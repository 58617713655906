import SEO from "../../components/seo";
import LuxeCancellationPage from "../../components/luxe/membershipDetails/CancellationPage";

function LuxeCancel() {
  return (
    <>
      <SEO title="Ana Luisa Luxe Cancellation" description="TODO: Add LUXE description" />
      <LuxeCancellationPage />
    </>
  );
}

export default LuxeCancel;
