import { useRef } from "react";
import shuffle from "lodash/shuffle";
import PropTypes from "prop-types";

const CANCEL_MAP = {
  benefits: "I was not happy with the benefits",
  "not-like": "I don’t like the jewelry",
  "not-buy": "I’m not buying enough jewelry",
  experience: "My experience was not great",
};

function CancelReasons({ cancelOption, setCancelOption, setErrorMessage }) {
  const cancelKeys = useRef(shuffle(Object.keys(CANCEL_MAP)));

  const onOptionChange = (e) => {
    setCancelOption(e.target.value);
    setErrorMessage("");
  };

  return cancelKeys.current?.map((key) => (
    <label htmlFor={key} key={key}>
      <input
        type="radio"
        id={key}
        value={key}
        checked={cancelOption === key}
        onChange={onOptionChange}
      />
      <span className="subtext">{CANCEL_MAP[key]}</span>
    </label>
  ));
}

CancelReasons.propTypes = {
  cancelOption: PropTypes.string,
  setCancelOption: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};

export default CancelReasons;
