import { useState } from "react";
import PropTypes from "prop-types";

import { Popup } from "../../Popup";
import { ALButton, ALLink } from "../../ALComponents";

import IconClose from "../../icons/svgs/close-round.svg";
import IconCheck from "../../icons/svgs/check.svg";

import "./pauseSubscriptionModal/styles.scss";

function PauseSubscriptionModal({ isOpen, onAction = () => {}, onClose }) {
  const [interval, setInterval] = useState(2);
  const [isSubscriptionPaused, setIsSubscriptionPaused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onPauseSubscription = async () => {
    setIsLoading(true);

    onAction(interval)
      .then(() => {
        setIsSubscriptionPaused(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Popup
      open={isOpen}
      modal
      closeOnDocumentClick
      className="pause-subscription-modal"
      onClose={onClose}
    >
      <button type="button" className="close-icon" onClick={onClose}>
        <IconClose height="30" width="30" fill="#FFF" stroke="#2d2927" />
      </button>
      <h3 className="h3 h3--bold">How long do you want to pause for?</h3>

      <div className="pause-subscription-modal-actions">
        <ALButton
          size="small"
          variant={interval === 2 ? "black" : "white"}
          disabled={isLoading || isSubscriptionPaused}
          onClick={() => setInterval(2)}
        >
          2 months
        </ALButton>
        <ALButton
          size="small"
          variant={interval === 3 ? "black" : "white"}
          disabled={isLoading || isSubscriptionPaused}
          onClick={() => setInterval(3)}
        >
          3 months
        </ALButton>
      </div>

      {isSubscriptionPaused ? (
        <ALLink to="/ana-luisa-luxe">
          <ALButton size="medium" variant="primary" fullWidth>
            Back to Luxe
          </ALButton>
        </ALLink>
      ) : (
        <ALButton
          size="medium"
          variant="primary"
          fullWidth
          onClick={onPauseSubscription}
          disabled={isLoading || isSubscriptionPaused}
          isLoading={isLoading}
        >
          Confirm Pause
        </ALButton>
      )}

      {isSubscriptionPaused && (
        <div className="mt-10 pause-subscription-confirmation">
          <IconCheck height="30" width="30" fill="#FFF" stroke="#2d2927" className="mr-5" />
          <span className="subtext">Success!</span>
        </div>
      )}
    </Popup>
  );
}

PauseSubscriptionModal.propTypes = {
  isOpen: PropTypes.bool,
  onAction: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default PauseSubscriptionModal;
